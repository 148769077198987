
import { defineComponent, onBeforeMount, onMounted,reactive,ref,toRefs } from "vue";
import SwiperIndex from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
// old
import MiddleBox from "./modules/middleBox.vue";
import MiddleBox2 from "./modules/middleBox2.vue";
import LongBox from "./modules/longBoxa.vue";
import BlueWideBox from "./modules/BlueWideBox.vue";
import BlueLongBox from "./modules/BlueLongBox.vue";
import Message from "@/components/message/Message.vue";
// new
import indexOneBox from "./newModules/indexOneBox.vue";
import indexTwoBox from "./newModules/indexTwoBox.vue";
import indexThreeBox from "./newModules/indexThreeBox.vue";
import indexFourBox from "./newModules/indexFourBox.vue";

// img
import axios from "@/utils/axios";
import { getIndex } from "@/api/common";

export default defineComponent({
  name: "home",
  components: {
    SwiperIndex,
    SubNav,
    MiddleBox,
    MiddleBox2,
    LongBox,
    BlueWideBox,
    BlueLongBox,
    Message,
    // new
    indexOneBox,
    indexTwoBox,
    indexThreeBox,
    indexFourBox,
  },
  data() {
    return {
      careerImg: require("@/assets/1new/首页/新生涯网站首页_06.jpg"),
      careerImgList: [
        require("@/assets/1new/首页/1.jpg"),
        require("@/assets/1new/首页/2.jpg"),
        require("@/assets/1new/首页/3.jpg"),
        require("@/assets/1new/首页/4.jpg"),
        require("@/assets/1new/首页/5.jpg"),
        require("@/assets/1new/首页/6.jpg"),
      ],
      advantageImg: require("@/assets/1new/首页/新生涯网站首页_30.jpg"),
      sixSpecial: require("@/assets/1new/首页/新生涯网站首页_34.jpg"),
      moreImg: require("@/assets/1new/首页/more.jpg"),
      littleImg: require("@/assets/1new/首页/小新快讯.jpg"),
      messList: [
        {
          id: 1,
          mess: "高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 2,
          mess: "2高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
      ],
    };
  },
  methods: {
    go(a: any) {
      var aim;
      switch (a) {
        case 0:
          aim = "professor";
          break;
        case 1:
          aim = "cooperation";
          break;
           case 2:
          aim = "study";
          break;
        case 3:
          aim = "career";
          break;
           case 4:
          aim = "excellent";
          break;
        case 5:
          aim = "consult";
          break;
      }
      console.log(aim);

      this.$router.push('/'+aim)
    },
    goLittle(a:any){
      console.log(a);
      this.$router.push('/newsDetail/'+a)
    }
  },
  setup() {
    console.log("首页setup");
    var mess = reactive({data:'小新快讯'})
    var video1 = reactive({data:'名师讲堂'})
    var video2 = reactive({data:'生涯讲堂'})
    var video3 = reactive({data:'大学展播'})
    var school  =reactive({data:'实践校'})
      // 1 小新快讯/生涯资讯
        axios
        .get(
          "https://www.xsy985.com/api/news/news/?page=1&pagesize=2&news_type=1"
        )
        .then(function (response) {
          // console.log(response.data);
          mess.data = response.data.results;
        })
        .catch(function (error) {
          console.log(error);
        });
      return{
        mess,
        video1,
        video2,
        video3,
        school
      }
    // 1
    // axios
    //   .get("http://49.232.142.48:3000/api/getUser")
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  },
});
