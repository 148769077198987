<template>
  <div class="box">
    <div class="boxHead">
      <span>公司新闻</span>
          <!-- <p>{{count}}  -- {{object.foo}}</p> -->
          <!-- <button @click="add">111</button> -->
      <span class="more">更多>></span>
      <span class="delete"></span>
    </div>
    <div class="boxMain">
      <div class="item" v-for="item in object" :key="item">
        <img class="img" :src="item.img" alt="" />
        <div class="right">
          <div class="top">{{ item.title }}</div>
          <div class="bottom">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import axios from "@/utils/axios";

import {ref,reactive} from 'vue';

export default defineComponent({
  name: "middleBox",
//   import {  nextTick} from "vue";  必须经过nextTick 否则不完整?
//  const funName= async () => {
//       await nextTick();
// }
  data() {
    return {
      list: [
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻1111",
          content:
            "这是什么新闻这是什么新闻这是什么新闻这是什么新闻这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "22",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻3",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻4",
          content: "这是什么新闻",
        },
      ],
    };
  },
  methods:{
  },
  setup() {
    const count = ref(2)
      const object = reactive({
        data:{},
      })
        // 2
    axios
      .get("http://49.232.142.48:8000/news/list")
      .then(function (response) {
        object.data = response;
        console.log('111111111',response,object);
      })
      .catch(function (error) {
        console.log(error);
      });
      return{
        object,
      }
//  function add(){
//    console.log(1,count,object.foo);
//     count.value++;
//      object.foo++;
//     }
//       return {
//         count,
//         object,
//         add
//       }
  
  },
});
</script>
<style lang='scss' scoped>
@import "~@/assets/styles/mixin.scss";
.box {
  width: 916px;
  height: 270px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  overflow: hidden;
  .boxHead {
    height: 40px;
    width: 916px;
    background-color: #f5f5f5;
    // border-radius: 10px;
    border-bottom: 1px solid #dddddd;
    font-size: 16px;
    color: #333;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 16px;
    .more {
      float: right;
    }
  }
  .boxMain {
    height: 210px;
    .item {
      width: 385px;
      height: 75px;
      margin-left: 55px;
      margin-top: 25px;
      display: inline-block;
    }
    .img {
      width: 75px;
      height: 75px;
      margin-right: 10px;
    }
    .right {
      display: inline-block;
      width: 300px;
      height: 50px;
      // background-color:pink;
      margin-bottom: 13px;
      position: relative;
      .top {
        position: absolute;
        top: 0;
        font-size: 16px;
        color: #337ab7;
      }
      .bottom {
        position: absolute;
        width: 300px;
        @include ellipsis;
        bottom: 0;
        font-size: 12px;
      }
    }
  }
}
</style>
