<template>
  <div class="box">
    <div class="boxHead">
      <span>专家团队</span>
      <!-- <span class="more">更多>></span> -->
    </div>
    <div class="boxMain">
      <div class="item" v-for="item in list" :key="item">
        <img class="img" :src="item.img" alt="">
        <div class="right">
          <div class="top">{{item.title}}</div>
          <div class="bottom">{{item.content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "longBox",
  data() {
    return {
      list: [
        { img: require("@/assets/首页/联系我们1.png"), title: "新闻1111", content: "这是什么新闻aaaaaaaaaa" },
        { img: require("@/assets/首页/联系我们1.png"), title: "22", content: "这是什么新闻" },
        { img: require("@/assets/首页/联系我们1.png"), title: "新闻3", content: "这是什么新闻" },
        { img: require("@/assets/首页/联系我们1.png"), title: "新闻4", content: "这是什么新闻" },
        { img: require("@/assets/首页/联系我们1.png"), title: "新闻4", content: "这是什么新闻" },
      ],
    };
  },
});
</script>
<style lang='scss' scoped>
  @import '~@/assets/styles/mixin.scss';
.box {
  width: 270px;
  height: 560px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  overflow: hidden;
  .boxHead {
    height: 40px;
    width: 270px;
    background-color: #f5f5f5;
    // border-radius: 10px;
    border-bottom: 1px solid #dddddd;
    font-size: 16px;
    color: #333;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 16px;
    .more {
      float: right;
    }
  }
  .boxMain {
    height: 520px;
    .item{
      width: 220px;
      height: 75px;
      margin-left: 35px;
      margin-top: 25px;
      display: inline-block;
    }
    .img{
      width: 75px;
      height: 75px;;
      margin-right: 10px;
      border-radius: 50%;
    }
    .right{
      display: inline-block;
      width: 130px;
      height: 50px;
      // background-color:pink;
      margin-bottom: 13px;
      position: relative;
      .top{
        position: absolute;
        top: 0;
        font-size: 16px;
        color: #337ab7;
      }
      .bottom{
        @include ellipsis;
        width: 130px;
        position: absolute;
        bottom: 0;
        font-size: 12px;
      }
    }
  }
}
</style>
