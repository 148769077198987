
import { defineComponent } from "vue";

import axios from "@/utils/axios";

import {ref,reactive} from 'vue';

export default defineComponent({
  name: "middleBox",
//   import {  nextTick} from "vue";  必须经过nextTick 否则不完整?
//  const funName= async () => {
//       await nextTick();
// }
  data() {
    return {
      list: [
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻1111",
          content:
            "这是什么新闻这是什么新闻这是什么新闻这是什么新闻这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "22",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻3",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻4",
          content: "这是什么新闻",
        },
      ],
    };
  },
  methods:{
  },
  setup() {
    const count = ref(2)
      const object = reactive({
        data:{},
      })
        // 2
    axios
      .get("http://49.232.142.48:8000/news/list")
      .then(function (response) {
        object.data = response;
        console.log('111111111',response,object);
      })
      .catch(function (error) {
        console.log(error);
      });
      return{
        object,
      }
//  function add(){
//    console.log(1,count,object.foo);
//     count.value++;
//      object.foo++;
//     }
//       return {
//         count,
//         object,
//         add
//       }
  
  },
});
