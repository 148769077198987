
import { defineComponent } from "vue";
export default defineComponent({
  name: "longBox",
  data() {
    return {
      list: [
        { img: require("@/assets/首页/联系我们1.png"), title: "新闻1111", content: "这是什么新闻aaaaaaaaaa" },
        { img: require("@/assets/首页/联系我们1.png"), title: "22", content: "这是什么新闻" },
        { img: require("@/assets/首页/联系我们1.png"), title: "新闻3", content: "这是什么新闻" },
        { img: require("@/assets/首页/联系我们1.png"), title: "新闻4", content: "这是什么新闻" },
        { img: require("@/assets/首页/联系我们1.png"), title: "新闻4", content: "这是什么新闻" },
      ],
    };
  },
});
