
import { defineComponent } from "vue";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/首页/新生涯网站首页轮播图01.jpg"),
        require("@/assets/1new/首页/新生涯网站首页轮播图02.jpg"),
        require("@/assets/1new/首页/新生涯网站首页轮播图03.jpg"),
      ],
    };
  },
  methods:{
    goXSY(){
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      window.open("https://cloud.xsy985.com")
    }
  }
});
