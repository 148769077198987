<template>
  <div class="main">
    <div class="box" :v-if="video1">
      <div class="boxHead">
        <span style="border-bottom: 3px solid #afe123; padding-bottom: 4px">{{
          title
        }}</span>
        <!-- <router-link  :to="{ name: aim, params:{id:id.data}}"> -->
          <img class="moreImg" :src="moreImg" alt=""
        />
        <!-- </router-link> -->

      </div>
      <div class="boxMain" v-for="item in video1.data" :key="item">
        <div class="item" @click="go1(item.id)" >
          <img class="img" :src="item.img" alt="" />
          <div class="bottom">
            <div class="one">{{ item.title }}</div>
            <div class="two">{{ item.resume }}</div>
            <div class="three">详细 >></div>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="boxMain" v-for="item in video2.data" :key="item">
        <div class="item" @click="go2(item.id)">
          <img class="img" :src="item.img" alt="" />
          <div class="bottom">
            <div class="one">{{ item.title }}</div>
            <div class="two">{{ item.resume }}</div>
            <div  class="three">详细 >></div>
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="boxMain" v-for="item in video3.data" :key="item">
        <div class="item" @click="go3(item.id)">
          <img class="img" :src="item.img" alt="" />
          <div class="bottom">
            <div class="one">{{ item.title }}</div>
            <div class="two">{{ item.resume }}</div>
            <div  class="three">详细 >></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";
import axios from "@/utils/axios";
export default defineComponent({
  name: "longBox",
  props: ["title", "aim", "data", "video1", "video2", "video3","id"],
  data() {
    return {
      now:this.$props,
      list: [
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻1111",
          content: "这是什么新闻aaaaaaaasssssssssssssaa",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "22",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻3",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻4",
          content: "这是什么新闻",
        },
      ],
      moreImg: require("@/assets/1new/首页/more.jpg"),
    };
  },
  methods: {
    go1(a:any){
      console.log(a);
      this.$router.push('/teacherLesson/'+a)
    },
    go2(a:any){
      console.log(a);
      this.$router.push('/careerLesson/'+a)
    },
    go3(a:any){
      console.log(a);
      this.$router.push('/college/'+a)
    },
  },
  setup(props) {
    // console.log(props.title,"props");
    // console.log(props, "props2.........");
    var video1 = reactive({ data: "" });
    var video2 = reactive({ data: "" });
    var video3 = reactive({ data: "" });
    var id = reactive({ data: "" });
    id.data =props.id
    if (props.video1 == "名师讲堂") {
      axios
        .get(
          "https://www.xsy985.com/api/course/course/?page=1&page_size=4&course_type=1"
        )
        .then(function (response) {
          // console.log(response.data,"名师讲堂");
          video1.data = response.data.results;
          // console.log(video1.data, "1");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (props.video2 == "生涯讲堂") {
      axios
        .get(
          "https://www.xsy985.com/api/course/course/?page=1&page_size=4&course_type=2"
        )
        .then(function (response) {
          // console.log("生涯讲堂",response.data);
          video2.data = response.data.results;
          // console.log(video2.data, "2");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (props.video3 == "大学展播") {
      axios
        .get(
          "https://www.xsy985.com/api/course/course/?page=1&page_size=4&course_type=3"
        )
        .then(function (response) {
          // console.log(response.data);
          video3.data = response.data.results;
          // console.log(video3.data, "3");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    return {
      video1,
      video2,
      video3,
      id
    };
  },
});
</script>
<style lang='scss' scoped>
@import "~@/assets/styles/mixin.scss";
.box {
  width: 835px;
  height: 340px;
  background-color: #fff;
  margin-bottom: 18px;
  padding-left: 12px;
  padding-top: 1px;
  .boxHead {
    width: 804px;
    height: 27px;
    position: relative;
    margin-top: 24px;
    border-bottom: 2px solid #17c8ce;
    font-size: 18px;
    color: #202021;
    font-weight: bold;
    margin-bottom: 14px;
    .moreImg {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 71px;
      height: 21px;
      cursor: pointer;
    }
  }
  .boxMain {
    width: 180px;
    height: 240px;
    overflow: hidden;
    display: inline-block;
    // background-color: pink;
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
    .item {
      cursor: pointer;
      .img {
        width: 180px;
        height: 120px;
        margin-bottom: 18px;
      }
      .bottom {
        font-size: 14px;
        line-height: 15px;
        .one {
          margin-bottom: 14px;
          font-weight: bold;
        }
        .two {
          height: 30px;
          overflow: hidden;
        }
        .three {
          position: absolute;
          bottom: 1px;
          cursor: pointer;
        }
      }
    }
  }
}
// .box {
//   width: 270px;
//   height: 560px;
//   box-sizing: border-box;
//   border: 1px solid #dddddd;
//   border-radius: 10px;
//   overflow: hidden;
//   .boxHead {
//     height: 40px;
//     width: 270px;
//     background-color: #f5f5f5;
//     // border-radius: 10px;
//     border-bottom: 1px solid #dddddd;
//     font-size: 16px;
//     color: #333;
//     line-height: 40px;
//     box-sizing: border-box;
//     padding: 0 16px;
//     .more {
//       float: right;
//     }
//   }
//   .boxMain {
//     height: 520px;
//     .item{
//       width: 220px;
//       height: 75px;
//       margin-left: 35px;
//       margin-top: 25px;
//       display: inline-block;
//     }
//     .img{
//       width: 75px;
//       height: 75px;;
//       margin-right: 10px;
//       border-radius: 50%;
//     }
//     .right{
//       display: inline-block;
//       width: 130px;
//       height: 50px;
//       // background-color:pink;
//       margin-bottom: 13px;
//       position: relative;
//       .top{
//         position: absolute;
//         top: 0;
//         font-size: 16px;
//         color: #337ab7;
//       }
//       .bottom{
//         @include ellipsis;
//         width: 130px;
//         position: absolute;
//         bottom: 0;
//         font-size: 12px;
//       }
//     }
//   }
// }
</style>
