<template>
  <div class="box">
    <div class="boxHead">
      <span style="border-bottom: 3px solid #afe123; padding-bottom: 4px"
        >生涯资讯</span
      >
     <router-link to="/newsList">
          <img class="moreImg" :src="moreImg" alt="" />
      </router-link>
      <!-- <span class="more">更多>></span> -->
    </div>
    <div class="main">
      <div class="left"  v-for="(item,i) in school.data" :key="i" v-show="i<=0" @click="go(item.id)">
        <div class="lImg">
          <img  class="innerimg" :src="item.img" alt="" v-show="i <= 0">
        </div>
        <p class="lMess">
          {{item.title}}
        </p>
      </div>
      <div class="right">
        <div class="boxMain" v-for="item in school.data.slice(1)" :key="item" @click="go(item.id)">
          <img class="rImg" :src="item.img" alt="" />
          <div class="item">
            <p class="one">{{ item.title }}</p>
            <p class="two">{{ item.resume }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";
import axios from "@/utils/axios";

export default defineComponent({
  name: "longBox",
  data() {
    return {
      list: [
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻1111",
          content: "这是什么新闻aaaaaaaasssssssssssssaa",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "22",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻3",
          content: "这是什么新闻",
        },
      ],
      moreImg: require("@/assets/1new/首页/more.jpg"),
    };
  },
  methods:{
 go(a:any){
      console.log(a);
      this.$router.push('/newsDetail/'+a)
    },
  },
  setup() {
    var school = reactive({ data: "" });

    axios
      .get(
        "https://www.xsy985.com/api/news/news/?page=1&page_size=4&news_type=1"
      )
      .then(function (response) {
        console.log(response.data);
        school.data = response.data.results;
        // console.log(video1.data, "视频zxujian");
      })
      .catch(function (error) {
        console.log(error);
      });

    return {
      school,
    };
  },
});
</script>
<style lang='scss' scoped>
@import "~@/assets/styles/mixin.scss";
.box {
  width: 1200px;
  min-height: 400px;
  background-color: #fff;
  margin-bottom: 18px;
  padding-left: 12px;
  padding-top: 1px;
  box-sizing: border-box;
  padding-bottom: 15px;
  .boxHead {
    width: 1200x;
    height: 27px;
    position: relative;
    margin-top: 24px;
    border-bottom: 2px solid #17c8ce;
    font-size: 18px;
    color: #202021;
    font-weight: bold;
    .moreImg {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 71px;
      height: 21px;
      cursor: pointer;
    }
  }
  .main {
    .left {
      margin-top: 21px;
      width: 466px;
      height: 315px;
      margin-right: 48px;
      float: left;

      .lImg {
        width: 466px;
        height: 230px;
        background-color: brown;
        margin-bottom: 30px;
        .innerimg{
           width: 466px;
        height: 230px;
        }
      }
      .lMess {
        font-size: 12px;
        line-height: 14px;
      }
    }
    .right {
      margin-top: 12px;
      width: 660px;
      // background-color: pink;
      height: 334px;
      float: left;
      .boxMain {
        height: 108px;
        width: 660px;
        .rImg {
          width: 165px;
          height: 102px;
          background-color: brown;
          float: left;
          margin-top: 3px;
          margin-right: 24px;
        }
        .item {
          float: left;
          width: 470px;
          height: 108px;
          border-bottom: 1px dashed #000;
          .one {
            margin-top: 8px;
            color: #333;
            font-size: 14px;
            margin-bottom: 16px;
          }
          .two {
            margin-top: 4px;
            font-size: 12px;
            color: #333;
            line-height: 20px;
          }
        }
      }
    }
  }
}
// .box {
//   width: 270px;
//   height: 560px;
//   box-sizing: border-box;
//   border: 1px solid #dddddd;
//   border-radius: 10px;
//   overflow: hidden;
//   .boxHead {
//     height: 40px;
//     width: 270px;
//     background-color: #f5f5f5;
//     // border-radius: 10px;
//     border-bottom: 1px solid #dddddd;
//     font-size: 16px;
//     color: #333;
//     line-height: 40px;
//     box-sizing: border-box;
//     padding: 0 16px;
//     .more {
//       float: right;
//     }
//   }
//   .boxMain {
//     height: 520px;
//     .item{
//       width: 220px;
//       height: 75px;
//       margin-left: 35px;
//       margin-top: 25px;
//       display: inline-block;
//     }
//     .img{
//       width: 75px;
//       height: 75px;;
//       margin-right: 10px;
//       border-radius: 50%;
//     }
//     .right{
//       display: inline-block;
//       width: 130px;
//       height: 50px;
//       // background-color:pink;
//       margin-bottom: 13px;
//       position: relative;
//       .top{
//         position: absolute;
//         top: 0;
//         font-size: 16px;
//         color: #337ab7;
//       }
//       .bottom{
//         @include ellipsis;
//         width: 130px;
//         position: absolute;
//         bottom: 0;
//         font-size: 12px;
//       }
//     }
//   }
// }
</style>
