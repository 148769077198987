
import { defineComponent } from "vue";
export default defineComponent({
  name: "BlueWideBox",
  data() {
    return {
      list: [
        {
          title: "常识技巧",
          sub: [
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },{
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
          ],
          content: [
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
          ],
        },
        {
          title: "生涯指南",
          content: [
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
          ],
        },
      ],
    };
  },
});
