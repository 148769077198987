<template>
  <div class="box">
    <div class="boxHead">
      <span>志愿填报热门问题↘</span>
      <span class="more">更多>></span>
    </div>
    <div class="boxMain">
      <div class="item" v-for="item in list" :key="item">
        <div class="head">
          <div class="line"></div>
          <span class="title">{{ item.title }}</span>
        </div>
        <div class="sub" >
          <div class="item" v-for="item in item.sub" :key="item">
            <img class="icon" :src="item.img" alt="">
          <p class="iconName">{{ item.name }}</p>

          </div>
        </div>
        <div class="main" v-for="item in item.content" :key="item">
          <img class="img" :src="item.img" alt="" />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "BlueWideBox",
  data() {
    return {
      list: [
        {
          title: "常识技巧",
          sub: [
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },{
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
          ],
          content: [
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
          ],
        },
        {
          title: "生涯指南",
          content: [
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何填报志愿",
            },
            {
              img: require("@/assets/首页/生涯云平台.png"),
              name: "如何选大学",
            },
          ],
        },
      ],
    };
  },
});
</script>
<style lang='scss' scoped>
@import "~@/assets/styles/mixin.scss";
.box {
  width: 910px;
  height: 770px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  overflow: hidden;
  .boxHead {
    height: 40px;
    width: 910px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    // border-radius: 10px;
    border-bottom: 2px solid #00a4ef;
    font-size: 28px;
    color: #00a4ef;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 16px;
    .more {
      float: right;
      font-size: 16px;
      color: #999;
    }
  }
  .boxMain {
    height: 910px;
    height: 100%;
    .item {
      .head {
        margin-bottom: 20px;
        .title {
          line-height: 24px;
          font-size: 20px;
          margin-left: 40px;
        }
        height: 24px;
        color: #333;
        // background-color: pink;
        .line {
          position: absolute;
          width: 5px;
          height: 24px;
          left: 20px;
          background-color: #00a4ef;
          display: inline-block;
        }
      }
      .sub{
        display: block;
        margin-bottom: 30px;
        .item{
          display: inline-block;
          margin-left: 20px;
          width: 160px;
          position: relative;
          .icon{
            width: 40px;
            height: 40px;
            position: absolute;
            top: 20px;
            left: 0px;
          }
          .iconName{
            font-size: 18px;
            line-height: 80px;
            margin-left: 50px;
            display: inline-block;
          }
        }
      }
      .main {
        width: 200px;
        height: 140px;
        display: inline-block;
        margin-left: 20px;
        margin-bottom: 30px;
        .img {
          width: 200px;
          height: 110px;
        }
      }
    }
  }
}
</style>
