
import { defineComponent, reactive } from "vue";
import axios from "@/utils/axios";
export default defineComponent({
  name: "longBox",
  props: ["title", "aim", "data", "video1", "video2", "video3","id"],
  data() {
    return {
      now:this.$props,
      list: [
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻1111",
          content: "这是什么新闻aaaaaaaasssssssssssssaa",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "22",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻3",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻4",
          content: "这是什么新闻",
        },
      ],
      moreImg: require("@/assets/1new/首页/more.jpg"),
    };
  },
  methods: {
    go1(a:any){
      console.log(a);
      this.$router.push('/teacherLesson/'+a)
    },
    go2(a:any){
      console.log(a);
      this.$router.push('/careerLesson/'+a)
    },
    go3(a:any){
      console.log(a);
      this.$router.push('/college/'+a)
    },
  },
  setup(props) {
    // console.log(props.title,"props");
    // console.log(props, "props2.........");
    var video1 = reactive({ data: "" });
    var video2 = reactive({ data: "" });
    var video3 = reactive({ data: "" });
    var id = reactive({ data: "" });
    id.data =props.id
    if (props.video1 == "名师讲堂") {
      axios
        .get(
          "https://www.xsy985.com/api/course/course/?page=1&page_size=4&course_type=1"
        )
        .then(function (response) {
          // console.log(response.data,"名师讲堂");
          video1.data = response.data.results;
          // console.log(video1.data, "1");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (props.video2 == "生涯讲堂") {
      axios
        .get(
          "https://www.xsy985.com/api/course/course/?page=1&page_size=4&course_type=2"
        )
        .then(function (response) {
          // console.log("生涯讲堂",response.data);
          video2.data = response.data.results;
          // console.log(video2.data, "2");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (props.video3 == "大学展播") {
      axios
        .get(
          "https://www.xsy985.com/api/course/course/?page=1&page_size=4&course_type=3"
        )
        .then(function (response) {
          // console.log(response.data);
          video3.data = response.data.results;
          // console.log(video3.data, "3");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    return {
      video1,
      video2,
      video3,
      id
    };
  },
});
