<template>
  <div class="home">
    <SwiperIndex></SwiperIndex>
    <!-- <SubNav></SubNav> -->
    <!-- 生涯一体化,小新快讯 -->
    <div class="one">
      <img
        class="careerImg"
        :src="careerImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <div>
        <img
          class="careerItem"
          v-for="(item, i) in careerImgList"
          :key="i"
          :src="item"
          alt=""
          @click="go(i)"
        />
      </div>
      <div class="little">
        <img
          class="littleImg"
          :src="littleImg"
          alt=""
          ondragstart="return false;"
          oncontextmenu="return false;"
        />
        <div class="littleMessage">
          <!-- 1111 -->
          <p class="mess" v-for="(item, i) in mess.data" :key="i" @click="goLittle(item.id)">
            {{ item.title }}
          </p>
        </div>
        <router-link to="/newsList/1"> <img
          class="moreImg"
          :src="moreImg"
          alt=""
          ondragstart="return false;"
          oncontextmenu="return false;"
        /></router-link>
      </div>
      <img
        class="advantageImg"
        :src="advantageImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
    </div>
    <!-- 名师讲堂 实践校 -->
    <div class="two">
      <index-one-box title="名师讲堂" aim="teacherLesson" :id="21" :video1=video1.data></index-one-box>
      <index-one-box title="生涯讲堂" aim="careerLesson" :id="26" :video2=video2.data></index-one-box>
      <index-one-box title="大学展播" aim="college" :id="30" :video3=video3.data></index-one-box>
      <!-- 实践校 -->
      <div class="school">
        <!-- practive ->newsDetail -->
        <index-two-box title="生涯一体化实践校" aim="newList" :school=school.data></index-two-box>
      </div>
    </div>
    <!-- 生涯资讯 -->
    <div class="career">
      <index-three-box></index-three-box>
    </div>
    <!-- 行业动态 -->
    <div class="industry">
      <index-four-box></index-four-box>
    </div>
    <!-- 六个特色 -->
    <div class="message">
      <message></message>
    </div>
    <!-- <div class="news">
      <MiddleBox></MiddleBox>
      <MiddleBox2 class="middle2"></MiddleBox2>
      < class="right">
        <long-box></long-box>
    </div>
    <div class="plan">
      <div class="left">
        <BlueWideBox></BlueWideBox>
      </div>
      <div class="right">
        <BlueLongBox></BlueLongBox>
      </div>
    </div> -->
  </div>
</template>
<script lang="ts">
import { defineComponent, onBeforeMount, onMounted,reactive,ref,toRefs } from "vue";
import SwiperIndex from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
// old
import MiddleBox from "./modules/middleBox.vue";
import MiddleBox2 from "./modules/middleBox2.vue";
import LongBox from "./modules/longBoxa.vue";
import BlueWideBox from "./modules/BlueWideBox.vue";
import BlueLongBox from "./modules/BlueLongBox.vue";
import Message from "@/components/message/Message.vue";
// new
import indexOneBox from "./newModules/indexOneBox.vue";
import indexTwoBox from "./newModules/indexTwoBox.vue";
import indexThreeBox from "./newModules/indexThreeBox.vue";
import indexFourBox from "./newModules/indexFourBox.vue";

// img
import axios from "@/utils/axios";
import { getIndex } from "@/api/common";

export default defineComponent({
  name: "home",
  components: {
    SwiperIndex,
    SubNav,
    MiddleBox,
    MiddleBox2,
    LongBox,
    BlueWideBox,
    BlueLongBox,
    Message,
    // new
    indexOneBox,
    indexTwoBox,
    indexThreeBox,
    indexFourBox,
  },
  data() {
    return {
      careerImg: require("@/assets/1new/首页/新生涯网站首页_06.jpg"),
      careerImgList: [
        require("@/assets/1new/首页/1.jpg"),
        require("@/assets/1new/首页/2.jpg"),
        require("@/assets/1new/首页/3.jpg"),
        require("@/assets/1new/首页/4.jpg"),
        require("@/assets/1new/首页/5.jpg"),
        require("@/assets/1new/首页/6.jpg"),
      ],
      advantageImg: require("@/assets/1new/首页/新生涯网站首页_30.jpg"),
      sixSpecial: require("@/assets/1new/首页/新生涯网站首页_34.jpg"),
      moreImg: require("@/assets/1new/首页/more.jpg"),
      littleImg: require("@/assets/1new/首页/小新快讯.jpg"),
      messList: [
        {
          id: 1,
          mess: "高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 2,
          mess: "2高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
      ],
    };
  },
  methods: {
    go(a: any) {
      var aim;
      switch (a) {
        case 0:
          aim = "professor";
          break;
        case 1:
          aim = "cooperation";
          break;
           case 2:
          aim = "study";
          break;
        case 3:
          aim = "career";
          break;
           case 4:
          aim = "excellent";
          break;
        case 5:
          aim = "consult";
          break;
      }
      console.log(aim);

      this.$router.push('/'+aim)
    },
    goLittle(a:any){
      console.log(a);
      this.$router.push('/newsDetail/'+a)
    }
  },
  setup() {
    console.log("首页setup");
    var mess = reactive({data:'小新快讯'})
    var video1 = reactive({data:'名师讲堂'})
    var video2 = reactive({data:'生涯讲堂'})
    var video3 = reactive({data:'大学展播'})
    var school  =reactive({data:'实践校'})
      // 1 小新快讯/生涯资讯
        axios
        .get(
          "https://www.xsy985.com/api/news/news/?page=1&pagesize=2&news_type=1"
        )
        .then(function (response) {
          // console.log(response.data);
          mess.data = response.data.results;
        })
        .catch(function (error) {
          console.log(error);
        });
      return{
        mess,
        video1,
        video2,
        video3,
        school
      }
    // 1
    // axios
    //   .get("http://49.232.142.48:3000/api/getUser")
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  },
});
</script>
<style lang="scss" scoped>
// .news {
//   width: 1200px;
//   height: 560px;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   position: relative;
//   // background-color: skyblue;
//   .middle2 {
//     margin-top: 20px;
//   }
//   .right {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
// }
// .plan {
//   width: 1200px;
//   // background-color: skyblue;
//   height: 770px;
//   margin-bottom: 20px;
//   position: relative;
//   .left {
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
//   .right {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
// }
.one {
  width: 100%;
  height: 540px;
  background-color: #fff;
  margin-top: 24px;
  margin-bottom: 24px;
  .careerImg {
    width: 1200px;
    height: 60px;
    // margin-left: 100px;
  }
  .careerItem {
    width: 181px;
    height: 196px;
    margin: 0 7px;
    cursor: pointer;
  }
  // 小新快讯
  .little {
    width: 1200px;
    height: 71px;
    margin: 20px 0 24px;
    // background-color: skyblue;
    position: relative;
    padding-top: 1px;
    .littleMessage {
      width: 640px;
      height: 51px;
      overflow: hidden;
      margin-left: 140px;
      margin-top: 12px;
      .mess {
        width: 440px;
        height: 17px;
        margin-bottom: 2px;
        font-size: 12px;
         white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 cursor: pointer;
      }
    }
    .moreImg {
      width: 61px;
      height: 18px;
      position: absolute;
      right: 14px;
      top: 24px;
      cursor: pointer;
    }
    .littleImg {
      position: absolute;
      top: 10px;
      left: 22px;
      width: 92px;
      height: 56px;
    }
  }
  // 那些优势
  .advantageImg {
    width: 1200px;
    height: 143px;
  }
}
.two {
  width: 100%;
  min-height: 980px;
  // background-color: pink;
  margin-bottom: 24px;
  position: relative;
  .school {
    position: absolute;
    width: 347px;
    height: 1024px;
    right: 0;
    top: 0;
  }
}
.career {
  width: 1200px;
  min-height: 400px;
  // background-color: skyblue;
  margin-bottom: 24px;
}
.industry {
  width: 100%;
  min-height: 628px;
  // background-color: pink;
  margin-bottom: 24px;
}
.message {
  width: 1200px;
  // background-color: skyblue;
  height: 100%;
  margin-bottom: 20px;
}
</style>
