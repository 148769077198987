
import { defineComponent, reactive } from "vue";
import axios from "@/utils/axios";

export default defineComponent({
  name: "longBox",
  data() {
    return {
      list: [
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻1111",
          content: "这是什么新闻aaaaaaaasssssssssssssaa",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "22",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻3",
          content: "这是什么新闻",
        },
      ],
      moreImg: require("@/assets/1new/首页/more.jpg"),
    };
  },
  methods:{
 go(a:any){
      console.log(a);
      this.$router.push('/newsDetail/'+a)
    },
  },
  setup() {
    var school = reactive({ data: "" });

    axios
      .get(
        "https://www.xsy985.com/api/news/news/?page=1&page_size=4&news_type=1"
      )
      .then(function (response) {
        console.log(response.data);
        school.data = response.data.results;
        // console.log(video1.data, "视频zxujian");
      })
      .catch(function (error) {
        console.log(error);
      });

    return {
      school,
    };
  },
});
