<template>
  <div class="home">
    <div class="swiperIndex">
      <el-carousel height="2.45rem">
        <el-carousel-item v-for="item,  in imgList" :key="item">
          <img @click="goXSY()" class="img" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/首页/新生涯网站首页轮播图01.jpg"),
        require("@/assets/1new/首页/新生涯网站首页轮播图02.jpg"),
        require("@/assets/1new/首页/新生涯网站首页轮播图03.jpg"),
      ],
    };
  },
  methods:{
    goXSY(){
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      window.open("https://cloud.xsy985.com")
    }
  }
});
</script>
<style lang='scss'>
.swiperIndex {
  width: 1920px;
    height: 497px;
  margin-left: -360px;
  cursor: pointer;
  .img {
    width: 1920px;
    height: 497px;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 497px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
