
import { defineComponent } from "vue";
export default defineComponent({
  name: "longBox",
  data() {
    return {
      list: [
        { img: require("@/assets/首页/生涯云平台.png"), title: "什么是平行志愿?", content: "10286人已经查看" },
        { img: require("@/assets/首页/生涯云平台.png"), title: "什么是平行志愿?", content: "10286人已经查看" },
        { img: require("@/assets/首页/生涯云平台.png"), title: "什么是平行志愿?", content: "10286人已经查看" },
        { img: require("@/assets/首页/生涯云平台.png"), title: "什么是平行志愿?", content: "10286人已经查看" },
        { img: require("@/assets/首页/生涯云平台.png"), title: "什么是平行志愿?", content: "10286人已经查看" },
        { img: require("@/assets/首页/生涯云平台.png"), title: "什么是平行志愿?", content: "10286人已经查看" },
        { img: require("@/assets/首页/生涯云平台.png"), title: "什么是平行志愿7?", content: "10286人已经查看" },
      ],
    };
  },
});
