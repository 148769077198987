<template>
  <div class="box">
    <div class="boxHead">
      <span style="border-bottom: 3px solid #afe123; padding-bottom: 4px">{{
        title
      }}</span>
      <router-link to="/newsList/3">
        <img class="moreImg" :src="moreImg" alt=""
      /></router-link>

      <!-- <span class="more">更多>></span> -->
    </div>
    <div class="boxMain" v-for="item in school.data" :key="item">
      <div class="item" @click="go(item.id)">
        <img class="img" :src="item.img" alt="" />
        <p class="one">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import axios from "@/utils/axios";
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "longBox",
  props: ["title", "aim", "school"],
  data() {
    return {
      list: [
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻1111",
          content: "这是什么新闻aaaaaaaasssssssssssssaa",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "22",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻3",
          content: "这是什么新闻",
        },
        {
          img: require("@/assets/首页/联系我们1.png"),
          title: "新闻4",
          content: "这是什么新闻",
        },
      ],
      moreImg: require("@/assets/1new/首页/more.jpg"),
    };
  },
  methods:{
 go(a:any){
      console.log(a);
      this.$router.push('/newsDetail/'+a)
    },
  },
  setup(props) {
    // console.log(props, "props2.........");
    var school = reactive({ data: "" });
    if (props.school == "实践校") {
      axios
        .get(
          "https://www.xsy985.com/api/news/news/?page=1&page_size=4&news_type=3"
        )
        .then(function (response) {
          // console.log(response.data);
          school.data = response.data.results;
          // console.log(video1.data, "视频zxujian");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    return {
      school,
    };
  },
});
</script>
<style lang='scss' scoped>
@import "~@/assets/styles/mixin.scss";
.box {
  width: 337px;
  height: 1057px;
  background-color: #fff;
  margin-bottom: 18px;
  padding-left: 12px;
  padding-top: 1px;
  .boxHead {
    width: 337px;
    height: 27px;
    position: relative;
    margin-top: 24px;
    border-bottom: 2px solid #17c8ce;
    font-size: 18px;
    color: #202021;
    font-weight: bold;
    margin-bottom: 14px;
    .moreImg {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 71px;
      height: 21px;
      cursor: pointer;
    }
  }
  .boxMain {
    width: 307px;
    height: 230px;
    overflow: hidden;
    display: inline-block;
    // background-color: pink;
    margin-left: 12px;
    margin-right: 12px;
    .item {
      .img {
        width: 307px;
        height: 205px;
      }
      margin-bottom: 2px;
      .one {
        // font-weight: bold;
        font-size: 14px;
        text-align: left;
        white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
      }
    }
  }
}
// .box {
//   width: 270px;
//   height: 560px;
//   box-sizing: border-box;
//   border: 1px solid #dddddd;
//   border-radius: 10px;
//   overflow: hidden;
//   .boxHead {
//     height: 40px;
//     width: 270px;
//     background-color: #f5f5f5;
//     // border-radius: 10px;
//     border-bottom: 1px solid #dddddd;
//     font-size: 16px;
//     color: #333;
//     line-height: 40px;
//     box-sizing: border-box;
//     padding: 0 16px;
//     .more {
//       float: right;
//     }
//   }
//   .boxMain {
//     height: 520px;
//     .item{
//       width: 220px;
//       height: 75px;
//       margin-left: 35px;
//       margin-top: 25px;
//       display: inline-block;
//     }
//     .img{
//       width: 75px;
//       height: 75px;;
//       margin-right: 10px;
//       border-radius: 50%;
//     }
//     .right{
//       display: inline-block;
//       width: 130px;
//       height: 50px;
//       // background-color:pink;
//       margin-bottom: 13px;
//       position: relative;
//       .top{
//         position: absolute;
//         top: 0;
//         font-size: 16px;
//         color: #337ab7;
//       }
//       .bottom{
//         @include ellipsis;
//         width: 130px;
//         position: absolute;
//         bottom: 0;
//         font-size: 12px;
//       }
//     }
//   }
// }
</style>
